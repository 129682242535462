<template>
  <div id="alarmList">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex ai-center h-100 top-data">
        <dv-border-box-10 class="mr-1 px-2" :color="['#568aea', 'rgba(86, 138, 234, .3)']">
          <div class="btn d-flex ai-center top-data-text">
            <span class="mr-2">全部报警数量</span>
            <span>{{total}}</span>
          </div>
        </dv-border-box-10>
        <dv-border-box-10 class="mx-1 px-2" :color="['#568aea', 'rgba(86, 138, 234, .3)']">
          <div class="btn d-flex ai-center top-data-text">
            <span class="mr-2">未处理报警数量</span>
            <span>{{untreatedCount}}</span>
          </div>
        </dv-border-box-10>
        <dv-border-box-10 class="ml-1 px-2" :color="['#568aea', 'rgba(86, 138, 234, .3)']">
          <div class="btn d-flex ai-center top-data-text">
            <span class="mr-2">已处理报警数量</span>
            <span>{{alreadyProcessedCount}}</span>
          </div>
        </dv-border-box-10>
      </div>
      <div class="d-flex ai-center py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="fire"></icon>
        </span>
        <div class="d-flex ai-center flex-1">
          <span class="text mx-2">实时告警情况</span>
        </div>
      </div>
      <div class="d-flex jc-center flex-1 body-box">
        <dv-scroll-board ref="alarmRef" :config="config" style="width:100%;height:100%;cursor:pointer;" @click="click" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        // header: ["告警时间", "告警类型", "告警设备", "告警地址", "方位", "监控"],
        header: ["时间", "类型", "设备", "地址"],
        data: [],
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        columnWidth: [80,80,180,255],
        rowNum: 3,
        index: false,
        align: ["center", "center", "center", "center", "center", "center"],
        hoverPause: true,
        waitTime: 1000
      },
      total: 0,
      untreatedCount: 0,
      alreadyProcessedCount: 0,
      list: []
    };
  },
  components: {},
  mounted() {},
  props: {
    obj: {
      type: Object,
      default () {
        return {}
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    obj: {
      handler(newData) {
        this.total = newData.total;
        this.untreatedCount = newData.untreatedCount;
        this.alreadyProcessedCount = newData.alreadyProcessedCount;
        var arr = [];
        newData.deviceList.forEach(element => {
          arr.push([
            `<div class='d-flex flex-column jc-center ai-center w-100 h-100 alarm-time' style='color:#11d0c9;'><span>${element.alarmTime.split(' ')[0].slice(5,10)}</span><span >${element.alarmTime.split(' ')[1].slice(0,5)}</span></div>`, 
            `<span class="alarm-device" style='color:#11d0c9;'>${element.deviceStatus == 1 ? '正常' : element.deviceStatus == 2 ? '离线' : element.deviceStatus == 3 ? '预警' : element.deviceStatus == 4 ? '火警' : '故障'}</span>`,
            `<span class="alarm-device" style='color:#11d0c9;'>${element.productName}</span>`, 
            `<div class='d-flex jc-center ai-center w-100 h-100'><span class='alarm-address' style='color:#11d0c9;width:300px' title='${element.detailAddress}'>${element.detailAddress} (${element.installPositionName})</span></div>`, 
            // `<span class="alarm-device" style='color:#11d0c9;'>${element.installPositionName}</span>`,
            // `<span style="color:#11d0c9">▶</span>`
          ]);
        });
        // var obj = JSON.parse(JSON.stringify(this.config));
        // obj.data = arr;
        // this.config = JSON.parse(JSON.stringify(obj));
        this.list = newData.deviceList;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.alarmRef.updateRows(arr);
          }, 0);
        });
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    click (e) {
      if (!this.isAdmin) {
        // this.list[e.rowIndex]
        this.$emit('alarmDetail');
      }
      this.$emit('playMonitor', this.obj.deviceList[e.rowIndex].monitorDeviceBaseInfoVO?.previewUrlHd);
    },
  }
};
</script>

<style lang="scss">
.alarm-address {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-time {
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-device {
  font-size: 0.15rem;
}
</style>
<style lang="scss" scoped>
#alarmList {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  .bg-color-black {
    width: 100%;
    height: 100%;
    position: relative;
    .top-data {
      position:absolute;
      top:-0.8rem;
      left: 0;
      height:0.5rem;
      &-text {
        color: #11d0c9;
        font-size: 0.1rem;
        font-weight: bold;
      }
    }
  }
  .text {
    font-size: 0.2rem;
    color: #11d0c9;
  }
  .btn {
    height: 100%;
    white-space: nowrap;
    font-size: 0.1rem;
    color: #c3cbde;
  }
  .body-box {
    height: 100%;
    overflow: hidden;
  }
}
</style>