<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body"  >
        <div class="d-flex jc-center" style="position:relative;">
          <dv-decoration-10 :color="['#568aea', 'rgba(86, 138, 234, .3)']"
            style="width:33.3%;height:.0625rem;transform: rotateY(180deg);" />
          <div class="d-flex jc-center ai-end" >
            <div class="title">
              <div class="d-flex jc-center ai-center gas-fhj">
                <img :src="require('../assets/gas.png')" />
              </div>
              <div class="title-text">{{title}}</div>
            </div>
          </div>
          <dv-decoration-10 :color="['#568aea', 'rgba(86, 138, 234, .3)']" style="width:33.3%;height:.0625rem;" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-center ai-end px-2">
          <dv-decoration-8 :color="['#568aea', '#568aea']" style="width:3rem;height:.625rem;" />
          <span style="font-size: 0.3rem;line-height:1.2;color:#01a2e8;margin:0 2rem;">{{dateYear}} {{dateWeek}}
            {{dateDay}}</span>
          <dv-decoration-8 :reverse="true" :color="['#568aea', '#568aea']" style="width:3rem;height:.625rem;" />
        </div>

        <div class="body-box flex-1">

          <div :class="areaType !== 4 ? 'left-box' : 'left-box3'">
            <div>
              <dv-border-box-12>
                <alarmList :obj="data.realTimeAlert" :isAdmin="isAdmin" @alarmDetail="alarmDetail"
                  @playMonitor="playMonitor" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <statistics :data="data.deviceMonthlyStatis" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <alarmRankList :list="data.lastWeekTowerEventRankList" :isAdmin="isAdmin" />
              </dv-border-box-12>
            </div>
            <div v-if="areaType !== 4">
              <dv-border-box-12>
                <abnormalTrend :data="data.lastWeekAbnormalDevList" />
              </dv-border-box-12>
            </div>
          </div>

          <div class="center-box">
            <div class="w-100 h-100">
              <dv-border-box-12>
                <chinaMap v-if="isAdmin" :fireAlarmCount="data.totalFireAlarmDeviceCount"
                  :malFunctionCount="data.totalMalfunctionDeviceCount" :totalCount="data.totalDevicesCount"
                  :data="chinaMapData" />
                <div v-else class="w-100 h-100 py-4 px-4">
                  <div class="w-100 h-100" style="border-radius:0.2rem;overflow: hidden;">
                    <baidu-map class="w-100 h-100" ak="VVzhhvMiEMU6HbYstY5LieXBeiiZcY0O" :center="center" :zoom="zoom"
                      :dragging="true" :scroll-wheel-zoom="true" @moveend="syncCenterAndZoom"
                      @zoomend="syncCenterAndZoom">
                      <bm-marker :position="center" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
                      </bm-marker>
                      <bm-control anchor="BMAP_ANCHOR_BOTTOM_RIGHT" class="w-100 d-flex jc-evenly mb-4">
                        <div class="bmap-control-btn">全部：{{total}}</div>
                        <div class="bmap-control-btn">火警：{{fire}}</div>
                        <div class="bmap-control-btn">异常：{{abnormal}}</div>
                        <div v-if="areaType !== 4" class="bmap-control-btn d-flex ai-center" style="position: relative;"
                          @click="showAreaList = !showAreaList">
                          <span class="mr-1">{{area ? area : '全部区域'}}</span>
                          <icon name="chevron-down"
                            :style="{transform: showAreaList ? 'rotate(180deg)' : 'rotate(0deg)'}"></icon>
                          <div v-if="showAreaList" class="bmap-control-list d-flex flex-column">
                            <div class="bmap-control-list-btn" v-for="(item, index) in areaList" :key="index"
                              @click="chooseArea(index)">
                              {{item.name}}
                            </div>
                          </div>
                        </div>
                      </bm-control>
                      <custom-overlay v-for="(item, index) in list" :key="index" :info="item"
                        @escapeRoutes="escapeRoutes"></custom-overlay>
                    </baidu-map>
                  </div>
                </div>
              </dv-border-box-12>
            </div>
          </div>

          <div class="right-box">
            <div>
              <dv-border-box-12>
                <monitor :list="monitorList" :isAdmin="isAdmin" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12 v-if="areaType !== 4">
                <access :data="isAdmin ? townStatisList : data.townStatisList" :isAdmin="isAdmin" />
              </dv-border-box-12>
              <dv-border-box-12 v-if="areaType === 4">
                <abnormalTrend :data="data.lastWeekAbnormalDevList" />
              </dv-border-box-12>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
    <el-dialog title="逃生路线详情" :visible.sync="escape">
      <img class="w-100" :src="escapeImg" />
    </el-dialog>
    <el-dialog title="楼宇基本信息" :visible.sync="showAlarmDetail">
      <alarmDetail @deviceList="deviceList" />
    </el-dialog>
    <el-dialog title="楼宇设备列表" :visible.sync="showDeviceList">
      <deviceList :info="fowerInfo" />
    </el-dialog>
  </div>
</template>

<script>
  import bus from '@/utils/bus';
  import {
    formatTime
  } from '../utils/index.js'
  import alarmList from "./alarmList";
  import statistics from "./statistics";
  import alarmRankList from "./alarmRankList";
  import abnormalTrend from "./abnormalTrend";
  import chinaMap from "./chinaMap";
  import monitor from "./monitor";
  import access from "./access";
  import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
  import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
  import BmControl from 'vue-baidu-map/components/controls/Control.vue';
  import CustomOverlay from '@/components/customOverlay';
  import AlarmDetail from '@/components/alarmDetail';
  import DeviceList from '@/components/deviceList';

  export default {
    data() {
      return {
        isAdmin: false,
        title: '国安达储能安全可视化管理系统',
        loading: true,
        dateTimer: null,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        timer: null,
        data: null,
        townStatisList: [],
        chinaMapData: [],
        center: '',
        zoom: 15,
        areaType: null,
        areaList: [],
        area: '',
        showAreaList: false,
        list: [],
        total: 0,
        fire: 0,
        abnormal: 0,
        escape: false,
        escapeImg: "",
        showAlarmDetail: false,
        showDeviceList: false,
        fowerInfo: null,
        monitorList: []
      };
    },
    components: {
      alarmList,
      statistics,
      alarmRankList,
      abnormalTrend,
      chinaMap,
      monitor,
      access,
      BaiduMap,
      BmMarker,
      BmControl,
      CustomOverlay,
      AlarmDetail,
      DeviceList
    },
    created() {
      // 5567bb2a30fe42b4bdc82cf82ec8d175
      if (this.$route.query.token) {
        document.title = '社区可视化大屏';
        localStorage.setItem('baseUrl', 'https://service.fhj.thesetech.com');
        localStorage.setItem('token', this.$route.query.token);
      } else {
        document.title = '国安达储能安全可视化管理系统';
      }
      this.isAdmin = localStorage.getItem('baseUrl').indexOf('admin') !== -1;
      this.getData();
      this.getMonitorList();

      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getData();
        this.getMonitorList();

        }, 120000);


    },
    mounted() {
      this.timeFn();
    },
    destroyed() {
      this.dateTimer && clearInterval(this.dateTimer);
      this.timer && clearInterval(this.timer);
    },
    methods: {
      playMonitor(e) {
        bus.$emit('monitorSetUrl', e);
      },
      escapeRoutes(e) {
        this.escape = true;
        this.escapeImg = e;
      },
      alarmDetail() {
        this.showAlarmDetail = true;
      },
      deviceList(e) {
        this.showDeviceList = true;
        this.fowerInfo = e;
      },
      syncCenterAndZoom(e) {
        const {
          lng,
          lat
        } = e.target.getCenter();
        this.center = {
          lng: lng,
          lat: lat
        };
        this.zoom = e.target.getZoom();
        this.getDeviceList(lng, lat);
      },
      timeFn() {
        this.dateTimer && clearInterval(this.dateTimer);
        this.dateTimer = setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH: mm: ss');
          this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
          this.dateWeek = this.weekday[new Date().getDay()];

        }, 1000)
      },
      getProvinceData() {
        if (this.isAdmin) {
          this.$get(this.$apis.adminDevstatisProvince, {
            limit: 1000,
            page: 1
          }).then(res => {
            if (res && res.code === 0) {
              var arr = [];
              res.data.list.forEach(element => {
                arr.push({
                  town: element.province,
                  towerCount: element.towerCount,
                  deviceCount: element.totalDevicesCount
                });
              });
              this.townStatisList = arr;
              this.chinaMapData = res.data.list;
            }
          });
        }
      },
      getData() {
        this.$get(this.isAdmin ? this.$apis.adminUserIndex : this.$apis.wechatUserIndex).then(res => {
          if (res && res.code === 0) {
            if (this.isAdmin) {
              this.title = '国安达储能安全可视化管理系统';
              res.data.realTimeAlert = {
                total: res.data.alarmDeviceCount,
                untreatedCount: res.data.unprocessedDevicesCount,
                alreadyProcessedCount: res.data.processedDevicesCount,
                deviceList: res.data.alarmDeviceList
              };
              var arr = [];
              res.data.regioRank.forEach(element => {
                arr.push({
                  tower: element.tower,
                  totalEventCount: element.eventCount
                });
              });
              res.data.lastWeekTowerEventRankList = arr;
              res.data.deviceCount = res.data.totalDevicesCount;
              res.data.deviceMonthlyStatis = res.data.eventStaticRatios;
              var abnormalTrend = [];
              res.data.weekTrend.forEach(element => {
                abnormalTrend.push({
                  time: element.statisTime.replace('-', '/'),
                  totalFireAlarmDeviceCount: element.totalFireAlarmDeviceCount,
                  totalMalfunctionDeviceCount: element.totalMalfunctionDeviceCount,
                  totalOfflineDeviceCount: element.totalOfflineDeviceCount,
                  growthRate: Math.floor((element.totalEventCount / element.totalDevicesCount) * 100)
                });
              });
              res.data.lastWeekAbnormalDevList = abnormalTrend;
            } else {
              if (res.data.roleType === 1) {
                this.$router.replace({
                  path: '/building',
                  query: {
                    token: localStorage.getItem('token')
                  }
                });
              }
              this.title = '消防监测平台';
              this.areaType = res.data.areaOperational.areaType;
              if (res.data.areaOperational.area) {
                this.title = `${res.data.areaOperational.area}消防监测平台`;
              }
              if (this.areaType === 4) {
                this.area = res.data.areaOperational.area;
              } else {
                this.areaList = res.data.areaOperational.areaList;
              }
              this.center = res.data.areaOperational.area;
              this.getDeviceList();
            }
            this.getProvinceData();
            this.data = res.data;
            setTimeout(() => {
              this.loading = false;
            }, 100);
          }
        });
      },
      getDeviceList(lng, lat) {
        var obj = {
          pageNum: 1,
          pageSize: 1000,
          status: 0,
          area: this.area
        }
        if (lng) {
          obj.longitude = lng;
        }
        if (lat) {
          obj.latitude = lat;
        }
        this.$get(this.$apis.deviceList, obj).then(res => {
          if (res && res.code === 0) {
            this.list = res.data.list;
            this.total = res.data.total;
            var fire = 0;
            var abnormal = 0;
            this.list.forEach(element => {
              if (element.deviceStatus == 4) {
                fire += 1;
              }
              if (element.deviceStatus != 1) {
                abnormal += 1;
              }
            });
            this.fire = fire;
            this.abnormal = abnormal;
          }
        });
      },
      chooseArea(i) {
        this.area = this.areaList[i].name;
        this.center = this.data.areaOperational.area + this.areaList[i].name;
        this.getDeviceList();
      },
      getMonitorList() {
        this.$get(this.isAdmin ? this.$apis.adminMonitorList : this.$apis.monitorList, {
          type: 0,
          keyword: ''
        }).then(res => {
          if (res && res.code === 0) {
            this.monitorList = res.data;
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '../assets/scss/index.scss';
</style>